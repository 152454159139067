import React, { useEffect } from "react";
import PageTitleContainer from "../../Components/PageTitleContainer/PageTitleContainer";
import backgroundImage from "./dumplings.jpg";
import { Box } from "@mui/material";
import "./Contact.css";
import ContactForm from "../../Components/ContactForm/ContactForm";

const Contact = () => {
  const title = "Contact";

  useEffect(() => {
    document.title = `${title} - Serious Sanji`;
  }, []);

  return (
    <>
      <PageTitleContainer title={title} backgroundImage={backgroundImage} />
      <Box
        className="formContainer"
        sx={{
          pt: { xs: "30px", sm: "30px", md: "40px" },
          pb: { xs: "30px", sm: "30px", md: "40px" },
          px: { xs: "40px", sm: "60px", md: "200px" },
        }}
      >
        <ContactForm />
      </Box>
    </>
  );
};

export default Contact;
