import React, { useEffect } from "react";
import PageTitleContainer from "../../Components/PageTitleContainer/PageTitleContainer";
import backgroundImage from "./dumplings.jpg";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import "./Menu.css";

const Menu = () => {
  const title = "Menu";

  useEffect(() => {
    document.title = `${title} - Serious Sanji`;
  }, []);

  return (
    <>
      <PageTitleContainer title={title} backgroundImage={backgroundImage} />
      <Grid
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        sx={{
          py: { xs: "50px", sm: "50px", md: "60px" },
          px: { xs: "40px", sm: "60px", md: "200px" },
        }}
      >
        <Grid item md={6} order={{ xs: 1, md: 1 }} sx={{ width: "100%" }}>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Appetizers / 前菜
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Cucumber Salad</TableCell>
                  <TableCell>凉拌黄瓜</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell>Scallion Pancake</TableCell>
                  <TableCell>葱油饼</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell>Mouth Watering Chicken</TableCell>
                  <TableCell>口水鸡</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$8.95</TableCell>
                </TableRow>
                <TableRow key="4">
                  <TableCell>Garlic Shredded Chicken</TableCell>
                  <TableCell>手撕鸡</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$8.95</TableCell>
                </TableRow>
                <TableRow key="5">
                  <TableCell>Squid Salad</TableCell>
                  <TableCell>鱿鱼沙拉</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$8.95</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Dim Sum / 点心
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Shrimp Siu Mai</TableCell>
                  <TableCell>虾烧麦</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="1">
                  <TableCell>Siu Mai</TableCell>
                  <TableCell>烧麦</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell>Braised Chicken Feet</TableCell>
                  <TableCell>凤爪</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell>Har Gao Crystal Shrimp Dumpling</TableCell>
                  <TableCell>虾饺</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="4">
                  <TableCell>Spare Ribs with Black Bean Sauce</TableCell>
                  <TableCell>排骨</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="5">
                  <TableCell>BBQ Pork Bun</TableCell>
                  <TableCell>酱香包</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="6">
                  <TableCell>Pork Chashu Bun</TableCell>
                  <TableCell>叉烧包</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="7">
                  <TableCell>Peking Duck Bun</TableCell>
                  <TableCell>北京片皮鸭</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$8.95</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Noodle & Rice / 面条与米饭
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Beef</TableCell>
                  <TableCell>牛</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$14.95</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell>Chicken</TableCell>
                  <TableCell>鸡</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$14.95</TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell>Shrimp</TableCell>
                  <TableCell>虾</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$14.95</TableCell>
                </TableRow>
                <TableRow key="4">
                  <TableCell>Veggie</TableCell>
                  <TableCell>素</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$11.95</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={6} order={{ xs: 2, md: 2 }} sx={{ width: "100%" }}>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Sanji / 生煎
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Pork</TableCell>
                  <TableCell>猪</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$8.95</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    Xiao Long Bao / 小笼包
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Shang Hai Soup Dumpling</TableCell>
                  <TableCell>上海小笼包</TableCell>
                  <TableCell>6PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$8.95</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell>Crab Meat & Pork Soup Dumpling</TableCell>
                  <TableCell>蟹粉小笼包</TableCell>
                  <TableCell>6PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$9.95</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    Pan Fried Dumpling / 锅贴
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Beef</TableCell>
                  <TableCell>牛</TableCell>
                  <TableCell>8PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$7.95</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell>Chicken</TableCell>
                  <TableCell>鸡</TableCell>
                  <TableCell>8PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$7.95</TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell>Pork</TableCell>
                  <TableCell>猪</TableCell>
                  <TableCell>8PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$7.95</TableCell>
                </TableRow>
                <TableRow key="4">
                  <TableCell>Veggie</TableCell>
                  <TableCell>素</TableCell>
                  <TableCell>8PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    Steam Dumpling / 水饺
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Beef</TableCell>
                  <TableCell>牛</TableCell>
                  <TableCell>8PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$7.95</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell>Chicken</TableCell>
                  <TableCell>鸡</TableCell>
                  <TableCell>8PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$7.95</TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell>Pork</TableCell>
                  <TableCell>猪</TableCell>
                  <TableCell>8PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$7.95</TableCell>
                </TableRow>
                <TableRow key="4">
                  <TableCell>Veggie</TableCell>
                  <TableCell>素</TableCell>
                  <TableCell>8PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    Soup / 汤
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Shrimp Wonton Soup</TableCell>
                  <TableCell>鲜虾馄饨汤</TableCell>
                  <TableCell>8PC</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$7.95</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={12} order={{ xs: 3, md: 3 }} sx={{ width: "100%" }}>
          <h2 style={{ textAlign: "center" }}>Drinks Menu</h2>
        </Grid>

        <Grid item md={6} order={{ xs: 4, md: 4 }} sx={{ width: "100%" }}>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Toppings / 底料
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Black Boba</TableCell>
                  <TableCell>珍珠</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$0.75</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell>Pudding</TableCell>
                  <TableCell>布丁</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$0.75</TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell>Lychee Jelly</TableCell>
                  <TableCell>荔枝椰果</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$0.75</TableCell>
                </TableRow>
                <TableRow key="4">
                  <TableCell>Crystal Boba</TableCell>
                  <TableCell>寒天水晶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$0.75</TableCell>
                </TableRow>
                <TableRow key="5">
                  <TableCell>Herbal Jelly</TableCell>
                  <TableCell>仙草</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$0.75</TableCell>
                </TableRow>
                <TableRow key="6">
                  <TableCell>Sago</TableCell>
                  <TableCell>西米</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$0.75</TableCell>
                </TableRow>
                <TableRow key="7">
                  <TableCell>Sago</TableCell>
                  <TableCell>黑糖脆啵啵</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$0.75</TableCell>
                </TableRow>
                <TableRow key="8">
                  <TableCell>Brown Sugar Boba</TableCell>
                  <TableCell>黑糖脆啵啵</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$0.75</TableCell>
                </TableRow>
                <TableRow key="9">
                  <TableCell>Popping Boba</TableCell>
                  <TableCell>爆爆珠</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$0.75</TableCell>
                </TableRow>
                <TableRow key="10">
                  <TableCell>Cheese Top</TableCell>
                  <TableCell>雪顶奶盖</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$1.25</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Iced Green Tea / 果茶类
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Mango Iced Green Tea</TableCell>
                  <TableCell>芒果绿茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell>Peach Iced Green Tea</TableCell>
                  <TableCell>水蜜桃绿茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell>Passion Fruit Iced Green Tea</TableCell>
                  <TableCell>百香果绿茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="4">
                  <TableCell>Kumquat Iced Green Tea</TableCell>
                  <TableCell>金桔柠檬</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="5">
                  <TableCell>Lychee Iced Green Tea</TableCell>
                  <TableCell>荔枝绿茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="6">
                  <TableCell>Green Apple Iced Green Tea</TableCell>
                  <TableCell>青苹果绿茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="7">
                  <TableCell>Grapefruit Iced Green Tea</TableCell>
                  <TableCell>西柚绿茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="8">
                  <TableCell>Orange Iced Green Tea</TableCell>
                  <TableCell>鲜橙绿茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="9">
                  <TableCell>Pineapple Iced Green Tea</TableCell>
                  <TableCell>凤梨绿茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="10">
                  <TableCell>Winter Melon Green Tea</TableCell>
                  <TableCell>冬瓜绿茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={6} order={{ xs: 5, md: 5 }} sx={{ width: "100%" }}>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Milk Tea / 奶茶类
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Original Milk Tea</TableCell>
                  <TableCell>原味奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell>Taro Milk Tea</TableCell>
                  <TableCell>芋香奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell>Japanese Milk Tea</TableCell>
                  <TableCell>日式奶绿</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="4">
                  <TableCell>Thai Iced Tea</TableCell>
                  <TableCell>泰式奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="5">
                  <TableCell>Rose Milk Green Tea</TableCell>
                  <TableCell>玫瑰奶绿</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="6">
                  <TableCell>Coffee Milk Tea</TableCell>
                  <TableCell>鸳鸯奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="7">
                  <TableCell>Lychee Milk Tea</TableCell>
                  <TableCell>荔枝奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="8">
                  <TableCell>Peach Milk Tea</TableCell>
                  <TableCell>水蜜桃奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="9">
                  <TableCell>Honeydew Milk Tea</TableCell>
                  <TableCell>哈密瓜奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="10">
                  <TableCell>Vanilla Milk Tea</TableCell>
                  <TableCell>香草奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="11">
                  <TableCell>Coconut Milk Tea</TableCell>
                  <TableCell>椰香奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="12">
                  <TableCell>Jasmine Milk Tea</TableCell>
                  <TableCell>茉香奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="13">
                  <TableCell>Mango Milk Tea</TableCell>
                  <TableCell>芒果奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="14">
                  <TableCell>Strawberry Milk Tea</TableCell>
                  <TableCell>草莓奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="15">
                  <TableCell>Sakura Rose Milk Tea</TableCell>
                  <TableCell>樱花玫瑰奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="16">
                  <TableCell>Mocha Milk Tea</TableCell>
                  <TableCell>摩卡奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="17">
                  <TableCell>Earl Milk Tea</TableCell>
                  <TableCell>伯爵奶茶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
                <TableRow key="18">
                  <TableCell>Mango Sago</TableCell>
                  <TableCell>杨枝甘露</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$5.35</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="menuTables">
            <Table aria-label="spanning table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Special Drink / 特别饮品
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell>Full Cup Orange</TableCell>
                  <TableCell>满杯香橙</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell>Full Cup Grapefruit</TableCell>
                  <TableCell>满杯西柚</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell>Cheese Top Strawberry</TableCell>
                  <TableCell>雪顶草莓</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="4">
                  <TableCell>Full Cup Pineapple Tea</TableCell>
                  <TableCell>满杯金菠萝</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="5">
                  <TableCell>Cheese Top Rose Black Tea</TableCell>
                  <TableCell>玫瑰红茶奶盖</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="6">
                  <TableCell>Milk Herbal Jelly</TableCell>
                  <TableCell>牛奶烧仙草</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="7">
                  <TableCell>Full Cup Jelly With Milk Tea</TableCell>
                  <TableCell>满杯烧仙草</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
                <TableRow key="8">
                  <TableCell>Brown Sugar Milk</TableCell>
                  <TableCell>黑糖珍珠鲜奶</TableCell>
                  <TableCell align="right" sx={{ display: "none" }}>$6.95</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default Menu;
