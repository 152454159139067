import React, { useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import bg from "./bg.jpg";
import "./Home.css";
import characterOne from "./character1.png";
import characterTwo from "./character2.png";
import dumplings from "./dumplings.jpg";

// TODO: Find free images, on top of the dumplings image, have text on top: We are Serious Sanji
// TODO: Customer Review slide... https://codepen.io/md-aqil/pen/abOVOKe
const Home = () => {
  useEffect(() => {
    document.title = "Serious Sanji";
  }, []);

  return (
    <>
      <Box
        sx={{
          px: "40px",
          background: `linear-gradient(0deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2)), url(${bg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          color: "white",
          position: "relative",
        }}
        className="firstContainer"
      >
        <Box sx={{ position: "relative", zIndex: "999" }}>
          <Box>
            <div className="neonLogo">
              <b>
                S<span>er</span>iou<span>s</span> <span>S</span>an<span>j</span>
                i
              </b>
            </div>
          </Box>
          <Box
            sx={{
              px: { xs: 0, md: "150px" },
              textAlign: "center",
              justifyContent: "center",
              pb: "40px",
            }}
          >
            <p
              style={{
                fontSize: "30px",
                fontFamily: "'Ma Shan Zheng', cursive",
              }}
            >
              正经生煎，带您感受指尖的韵味，品尝舌尖的
              美味，一口入魂，齿颊生香，东方匠人， 馈赠天下。
            </p>
            <p
              style={{
                fontSize: "30px",
                fontFamily: "'Solitreo', cursive",
              }}
            >
              Serious Dumpling take you to feel the rhythm of fingertips, taste
              the deliciousness of tongue. Fascinated for just one bite.
              Aromatic to the teeth made from oriental craftsman, a gift to the
              world.
            </p>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              href="#/menu"
            >
              View Menu
            </Button>
          </Box>
        </Box>
      </Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
        sx={{
          py: { xs: "20px", sm: "50px", md: "60px" },
          px: { xs: "40px", sm: "60px", md: "200px" },
        }}
      >
        <Grid
          item
          md={3}
          order={{ xs: 1, md: 1 }}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "150px", sm: "200px" },
              maxWidth: "100%",
            }}
          >
            <img
              src={characterOne}
              alt="Serious Sanji Boy Mascot (喜包包) holding Bubble Tea"
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </Grid>
        <Grid item md={9} order={{ xs: 2, md: 2 }}>
          <p
            style={{
              fontSize: "30px",
              fontFamily: "'Solitreo', cursive",
            }}
          >
            The traditional Chinese food from the far East, Sanji has a history
            of over 100 years, transforming from dim sum to breakfast, then to
            full meal. The evolution of Sanji comes from the faithful
            affirmation of diners due to it is delicious and tasty.
          </p>
          <p
            style={{
              fontSize: "30px",
              fontFamily: "'Ma Shan Zheng', cursive",
            }}
          >
            来自遥远东方的中国传统美食生煎，至今已有一百多年的历史，从点心到早点，从早点到正餐，
            生煎的发展进化正是来源于食客对其活色生香、有滋有味的忠实肯定。
          </p>
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: { xs: "200px", md: "280px" },
          textAlight: "center !important",
          background: `linear-gradient(0deg, rgba(51, 51, 51, 0.20), rgba(51, 51, 51, 0.20)), url(${dumplings})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          color: "white",
          backgroundAttachment: "fixed",
          textTransform: "uppercase",
          textShadow: "0 2px 0 rgba(0, 0, 0, 0.5)",
          fontSize: "1.500em",
          paddingBottom: "20px",
        }}
      >
        <h2>生煎 / Sanji</h2>
      </Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
        sx={{
          py: { xs: "20px", sm: "50px", md: "60px" },
          px: { xs: "40px", sm: "60px", md: "200px" },
        }}
      >
        <Grid item md={9} order={{ xs: 4, md: 3 }}>
          <p
            style={{
              fontSize: "30px",
              fontFamily: "'Solitreo', cursive",
            }}
          >
            Today, the traditional Chinese Sanji, our Serious Dumpling has come
            to the United States with western style, but retains the original
            oriental taste. It not only satisfies your memory of childhood taste
            and the consolation of endless yearning in a foreign country, but it
            is also the best gift to global friends who love Chinese food. Food
            makes life better and the world more wonderful.
          </p>
          <p
            style={{
              fontSize: "30px",
              fontFamily: "'Ma Shan Zheng', cursive",
            }}
          >
            今天，选料精良、制作用心、口味地道的中国传统生煎，正经生煎，褪汉服、着西装，漂洋过海，
            来到美国，却保留了原汁原味的东方手艺。它不仅满足异乡的你，
            对童年味道的追忆和无限思念的慰籍，它还是对热爱中国美食的世界朋友，最好的礼遇和馈赠，
            美食让生活更美好，让世界更美妙。
          </p>
        </Grid>
        <Grid
          item
          md={3}
          order={{ xs: 3, md: 4 }}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: { xs: "150px", sm: "200px" }, maxWidth: "100%" }}>
            <img
              src={characterTwo}
              alt="Serious Sanji Boy Mascot (喜包包)"
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
