import React, { useEffect } from "react";
import PageTitleContainer from "../../Components/PageTitleContainer/PageTitleContainer";
import backgroundImage from "./make-dumplings.jpg";
import { Grid, Alert, Box } from "@mui/material";
import dumplingOne from "./dumpling1.jpg";
import ContactForm from "../../Components/ContactForm/ContactForm";

const Franchise = () => {
  const title = "Franchise";

  useEffect(() => {
    document.title = `${title} - Serious Sanji`;
  }, []);

  return (
    <>
      <PageTitleContainer title={title} backgroundImage={backgroundImage} />
      <Grid
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        sx={{
          pt: { xs: "50px", sm: "50px", md: "60px" },
          pb: { xs: "20px", sm: "20px", md: "20px" },
          px: { xs: "40px", sm: "60px", md: "200px" },
        }}
      >
        <Alert severity="info" sx={{ width: "100%", mb: "20px", fontSize: "16px" }}>
          Please fill out and submit the application form below and one of our sales representatives will contact you ASAP. For faster service, please email us at <a href="mailto:info@serious-sanji.com">info@serious-sanji.com</a>
        </Alert>
        <Grid
          item
          md={6}
          order={{ xs: 2, md: 1 }}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            padding: "0px !important",
            margin: "0px !important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img src={dumplingOne} alt="Soup Dumpling" style={{ maxWidth: "100%" }} />
        </Grid>
        <Grid
          item
          md={6}
          order={{ xs: 1, md: 2 }}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            color: "white",
            backgroundColor: "primary.main",
            pb: "10px",
            pr: "30px",
            fontSize: "18px",
          }}
        >
          <div>
            <ol>
              <li>Fill out the inquiry form</li>
              <li>A sales representative will contact you via email</li>
              <li>Receive franchise disclosure document (FDD)</li>
              <li>Look for the best possible store location (the Serious Sanji Team will provide professional recommendations for location screening and final approval)</li>
              <li>Sign franchise agreement and lease for approved store location Store Renovation (the Serious Sanji team will provide a standard store layout and interior design)</li>
              <li>Training Program</li>
              <li>Grand Opening (The Serious Sanji Team will have a senior staff member present to assist you during the grand opening period )</li>
            </ol>
          </div>
        </Grid>
      </Grid>
      <Box
        sx={{
          pb: { xs: "50px", sm: "50px", md: "60px" },
          px: { xs: "40px", sm: "60px", md: "200px" },
        }}
      >
        <ContactForm />
      </Box>
    </>
  );
};

export default Franchise;
